import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Pages/Layout";
// import Home from "./Pages/Home";
import View from "./Pages/View";
import Create from "./Pages/Create";
import { StoreProvider } from "./context/contextStore";
import SignUp from "./Pages/auth/signUp";
import SignIn from "./Pages/auth/signIn";
import useStore from "./Hooks/useStore";
import IsLogin from "./Pages/auth/IsLogin";
import PersistLogin from "./Pages/auth/PersistLogin";
import RequireAuth from "./Pages/auth/requireAuth";
import NotAuthorized from "./Pages/NotAuthorized";
import { Component } from "react";
function App() {
  // const { auth } = useStore();
  const roles = {
    ADMIN: 100,
    DEVELOPER: 200,
  };
  const allBased = [
    {
      path: "/view",
      component: <View />,
      permissions: [roles.ADMIN, roles.DEVELOPER],
    },
    {
      path: "/create",
      component: <Create />,
      permissions: [roles.ADMIN],
    },
  ];
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />

          <Route element={<PersistLogin />}>
            <Route path="/" element={<Layout />}>
              {allBased.map((data, index) => {
                return (
                  <Route
                    key={index}
                    element={<RequireAuth allowedRoles={data.permissions} />}
                  >
                    <Route path={data.path} element={data.component} />
                  </Route>
                );
              })}
              {/* <Route element={<RequireAuth allowedRoles={[roles.ADMIN]} />}>
                <Route path="/create" element={<Create />} />
              </Route> */}
              {/* <Route
                element={
                  <RequireAuth allowedRoles={[roles.ADMIN, roles.DEVELOPER]} />
                }
              >
                <Route path="/view" element={<View />} />
              </Route> */}
            </Route>
          </Route>
          <Route path="*" element={<NotAuthorized />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
