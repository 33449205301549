import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
// import useRefreshToken from "../hooks/useRefreshToken";
import useStore from "../../Hooks/useStore";
import useRefreshToken from "../../Hooks/useRefreshToken";
// import useAuth from "../hooks/useAuth";
const PersistLogin = () => {
  const [loading, setLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useStore();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    !auth?.accessToken ? verifyRefreshToken() : setLoading(false);
  }, []);
  return (
    <>{!persist ? <Outlet /> : loading ? <p>Loading...</p> : <Outlet />}</>
  );
};
export default PersistLogin;
