import { Link } from "@mui/material";
import { Image } from "antd";
import { memo } from "react";
const CustomImageShow = memo(({ link }) => {
  if (!link) {
    return;
  }
  return (
    <div
      // className="size-[40px] rounded overflow-hidden duration-150"
      style={{
        width: "40px",
        height: "40px",
        borderRadius: "4px",
        overflow: "hidden",
        transitionDuration: ".15s",
      }}
    >
      <Image src={link} />
    </div>
  );
});
export default CustomImageShow;
