import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import { memo } from "react";
const CustomInput = memo(
  ({
    size = "small",
    value = "",
    placeholder = "",
    id = "",
    label = "",
    variant = "outlined",
    type = "text",
    className = "",
    name = "",
    withButton = false,
    icon = null,
    loading = false,
    disabled = false,
    postfix = false,
    postfixLabel = "",
    optionValue = false,
    handelChange = () => {},
    handelButtonClick = () => {},
    multiline = false,
    maxRows = null,

    // values=[]
    activeOptions,
  }) => {
    console.log("customInput Rendered");
    // console.log(optionValue, "optionValue");
    // console.log(readOnly, "readOnly");
    return !withButton ? (
      <TextField
        multiline={multiline}
        rows={maxRows}
        value={value}
        name={name}
        className={className}
        onChange={handelChange}
        size={size}
        placeholder={placeholder}
        id={id}
        label={label}
        variant={variant}
        type={type}
        disabled={disabled}
        // slotProps={{
        //   input: {
        //     readOnly: { readOnly },
        //   },
        // }}
      ></TextField>
    ) : (
      <FormControl
        // sx={{ width: "25ch" }}
        variant="outlined"
        className={className}
        size="small"
        disabled={loading}
      >
        <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
        {optionValue ? (
          <Select
            id={id}
            value={value}
            onChange={handelChange}
            name={name}
            // placeholder={placeholder}
            variant={variant}
            type={type}
            // endAdornment={
            //   <InputAdornment position="end">
            //     {postfix ? (
            //       postfixLabel
            //     ) : (
            //       <IconButton
            //         disabled={loading}
            //         onClick={handelButtonClick}
            //         edge="end"
            //       >
            //         {true ? icon : null}
            //       </IconButton>
            //     )}
            //   </InputAdornment>
            // }
            label={label}
          >
            {activeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <OutlinedInput
            id={id}
            value={value}
            onChange={handelChange}
            name={name}
            placeholder={placeholder}
            variant={variant}
            type={type}
            endAdornment={
              <InputAdornment position="end">
                {postfix ? (
                  postfixLabel
                ) : (
                  <IconButton
                    disabled={loading}
                    onClick={handelButtonClick}
                    edge="end"
                  >
                    {true ? icon : null}
                  </IconButton>
                )}
              </InputAdornment>
            }
            label={label}
            select={optionValue ? true : false}
          />
        )}
      </FormControl>
    );
  }
);
export default CustomInput;
