import { Button } from "antd";
import "./NotAuthorized.css";
import { useLocation, useNavigate } from "react-router-dom";
const NotAuthorized = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const from = "/";
  // console.log(from, "from");

  return (
    <div className="notAuthorizedBody">
      <div class="lock"></div>
      <div class="message">
        <h1>Access to this page is restricted</h1>
        <p>
          Please check with the site admin if you believe this is a mistake.
        </p>
        <div style={{ display: "flex" }}>
          <Button
            // className="bg-black"
            style={{
              marginTop: "10px",
              marginInline: "auto",
              fontWeight: "500",
              // color: "black",
            }}
            type="primary"
            onClick={() => {
              navigate(from, { replace: true });
            }}
          >
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};
export default NotAuthorized;
