import { useState } from "react";
import "./sign-up-form.css";
import axios from "../../../api/axios/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export function SignUpForm() {
  const [formData, setFormData] = useState({});
  const GET_DEALS_URL = "/api/auth/signup";
  const navigate = useNavigate();
  const handleChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.cpassword) {
      toast.error("Confirm Passwords do not match");
      return;
    }

    const { name, email, password } = formData;

    try {
      const response = await axios.post(GET_DEALS_URL, {
        name,
        email,
        password,
      });
      // console.log("response", response);
      toast.success(response.data.message);
      navigate("/signin");
      // alert(response.data.message);
    } catch (error) {
      console.log(error.message);
      toast.error(error.response.data.message);

      // alert(error.message);
    }
  };

  return (
    <div className="new-body">
      <div className="wrapper">
        <h2>Registration</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-box">
            <input
              type="text"
              name="name"
              placeholder="Enter your name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-box">
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              name="password"
              placeholder="Create password"
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-box">
            <input
              type="password"
              name="cpassword"
              placeholder="Confirm password"
              required
              onChange={handleChange}
            />
          </div>
          <div className="input-box button">
            <input type="submit" value="Register Now" />
          </div>
        </form>
        <div className="text">
          <h3>
            Already have an account? <a href="/signIn">Login now</a>
          </h3>
        </div>
      </div>
    </div>
  );
}
