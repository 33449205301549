import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import CreateIcon from "@mui/icons-material/Create";
import PreviewIcon from "@mui/icons-material/Preview";
import PermMediaOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActual";
import PublicIcon from "@mui/icons-material/Public";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import TimerIcon from "@mui/icons-material/Timer";
import SettingsIcon from "@mui/icons-material/Settings";
import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location.pathname.split("/")[1], "location");
  const path = location.pathname.split("/")[1];
  const categories = [
    {
      id: "Build",
      children: [
        {
          id: "create",
          icon: <CreateIcon />,
          active: path === "create",
        },
        { id: "view", icon: <PreviewIcon />, active: path === "view" },
        // { id: "Storage", icon: <PermMediaOutlinedIcon /> },
        // { id: "Hosting", icon: <PublicIcon /> },
        // { id: "Functions", icon: <SettingsEthernetIcon /> },
        // {
        //   id: "Machine learning",
        //   icon: <SettingsInputComponentIcon />,
        // },
      ],
    },
    // {
    //   id: "Quality",
    //   children: [
    //     { id: "Analytics", icon: <SettingsIcon /> },
    //     { id: "Performance", icon: <TimerIcon /> },
    //     { id: "Test Lab", icon: <PhonelinkSetupIcon /> },
    //   ],
    // },
  ];

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}
        >
          DEALS APP
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Project Overview</ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active }) => (
              <ListItem disablePadding key={childId}>
                <ListItemButton
                  selected={active}
                  sx={item}
                  onClick={() => navigate(childId)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText className="capitalize">{childId}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
