// import axios from "../api/axios/axios";
// import useAuth from "./useAuth";
import axios from "../api/axios/axios";
import useStore from "./useStore";

const useRefreshToken = () => {
  const { setAuth } = useStore();

  const refresh = async () => {
    try {
      const response = await axios.get("/api/auth/refresh", {
        withCredentials: true,
      });
      // console.log(response, "response");

      setAuth((prev) => ({
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
        email: response.data.email,
      }));

      return response.data.accessToken;
    } catch (error) {
      console.error(error);
    }
  };
  return refresh;
};
export default useRefreshToken;
