import React, { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const LineChart = memo(({ graphData }) => {
  // console.log(graphData, "graphData");
  // Extracting the required data for the chart
  const times = graphData?.map((item) => item.time);
  const minPrices = graphData?.map((item) => item.min_price);
  const maxPrices = graphData?.map((item) => item.max_price);
  // console.log(maxPrices, "maxPrize");
  const [maximumPrice, setMaximumPrice] = useState(0);
  const [minimumPrice, setMinimumPrice] = useState(0);

  useEffect(() => {
    if (maxPrices?.length > 0) {
      setMaximumPrice(Math.max(...maxPrices));
      setMinimumPrice(Math.min(...minPrices));
      //   console.log(maximumPrice, "maximumPrize");
    }
  }, [maxPrices]);

  // Formatting dates for the x-axis
  // console.log(maximumPrice.toString().length, "length", maximumPrice);
  const labels = times
    ?.sort((a, b) => new Date(a) - new Date(b))
    ?.map((date) => {
      const formattedDate = new Date(date);
      return `${formattedDate.getFullYear()}-${
        formattedDate.getMonth() + 1
      }-${formattedDate.getDate()}`;
    });

  // Defining chart options
  const options = {
    chart: {
      type: "line",
      zoom: { enabled: false },
      toolbar: { show: false },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      categories: labels,
      labels: { format: "dd MMM" },
      title: { text: "Date" },
    },
    yaxis: {
      labels: {
        formatter: (value) => `Rs. ${value}`,
      },
      title: { text: "Price" },
      min: Math.max(minimumPrice - 100, 0),
      max:
        maximumPrice.toString().length <= 3
          ? maximumPrice + 100
          : maximumPrice + 1000,
    },
    tooltip: {
      x: { format: "dd MMM yyyy" },
      y: {
        formatter: (value) => {
          // console.log(value, "value");
          return `Rs. ${value} `;
        },
      },
    },
    markers: {
      size: 4,
      colors: ["#A0A0A0"],
      strokeColors: "#fff",
      strokeWidth: 2,
    },
    colors: ["#77B6EA", "#545454"],
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
  };

  // Series data for the chart
  const series = [
    {
      name: "Max Price",
      data: maxPrices,
    },
    {
      name: "Min Price",
      data: minPrices,
    },
  ];

  return (
    <div className="graph-wrapper" style={{padding:"8px"}}>
      <Chart options={options} series={series} type="line" height="100%" />
    </div>
  );
});

export default LineChart;
